dmx.Component('browser', {

    initialData: {
        online: navigator.onLine,
        doNotTrack: false,
        userAgent: navigator.userAgent,
        language: navigator.language,
        cookieEnabled: navigator.cookieEnabled,

        location: window.location,

        viewport: {
            width: 0,
            height: 0
        },

        device: {
            width: 0,
            height: 0,
            pixelRatio: 1,
            orientation: 'landscape'
        },

        document: {
            width: 0,
            height: 0,
            hidden: document.hidden,
            visibility: document.visibilityState
        }
    },

    methods: {
        goto: function(location) {
            window.location = location;
        },

        alert: function(message) {
            window.alert(message);
        }
    },

    render: function(node) {
        dmx.BaseComponent.prototype.render.call(this, node);

        window.addEventListener('resize', this.update.bind(this));
        window.addEventListener('online', this.update.bind(this));
        window.addEventListener('offline', this.update.bind(this));
        window.addEventListener('visibilitychange', this.update.bind(this));
        if (window.orientation) window.addEventListener('orientationchange', this.update.bind(this));

        this.update();
    },

    update: function() {
        this.set('online', navigator.onLine);
        this.set('doNotTrack', navigator.doNotTrack == '1' || window.doNotTrack == '1' || navigator.msDoNotTrack == '1');
        this.set('userAgent', navigator.userAgent);
        this.set('language', navigator.language);
        this.set('cookieEnabled', navigator.cookieEnabled);

        this.set('location', window.location);

        this.set('viewport', {
            width: window.innerWidth,
            height: window.innerHeight
        });

        this.set('device', {
            width: window.screen.width,
            height: window.screen.height,
            pixelRatio: window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI,
            orientation: this.getOrientation()
        });

        this.set('document', {
            width: Math.max(document.body.scrollWidth, document.documentElement.scrollWidth),
            height: Math.max(document.body.scrollHeight, document.documentElement.scrollHeight),
            hidden: document.hidden,
            visibility: document.visibilityState
        });
    },

    getOrientation: function() {
        if (window.matchMedia("(orientation: portrait)").matches) {
            return 'portrait';
        } else {
            return 'landscape';
        }
    }

});
